

























































































































import { Parametro } from "@/core/models/geral";
import { ParametroService, TurmaService } from "@/core/services/geral";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class RelatorioPage extends Vue {

    titulo: string = 'Impressão Certificado';
    paisagem: boolean = true;
    exportarExcel: boolean = false;
    data: any = Date.now;

    parametro: Parametro = new Parametro();
    parametroService: ParametroService = new ParametroService();

    turmaService = new TurmaService();
    item: any = null;

    certificado: any = null

    filtro: any = {
        turmaId: 0,
        fundo: true,
        idAluno: "",
        idUsuario: ""
    }

    overlay: boolean = false;

    mounted() {

        let root = document.documentElement;

        if(this.paisagem){
            root.style.setProperty("--widthPage", 29.7 + 'cm');
            root.style.setProperty("--min-heightPage", 19 + 'cm');
            this.setPageSize('landscape')
        }      
        else{
            root.style.setProperty("--widthPage", 21 + 'cm');
            root.style.setProperty("--min-heightPage", 26 + 'cm');
            this.setPageSize('portrait')
        }

        this.filtro = {
            turmaId: this.$route.query.turmaId,
            fundo: this.$route.query.fundo == "true" ? true : false,
            idAluno: this.$route.query.idAluno,
            idUsuario: this.$route.query.idUsuario
        }

        if(this.filtro.fundo){
            this.parametroService.ObterPorId(1).then(
                res => {
                    this.parametro = res.data;
                    if(this.filtro.fundo)
                        this.certificado = this.parametro.certificado;
                },
                err =>{
                    this.$swal('Aviso',err.response.data,'error')
                }
            )
        }

        this.Carregar();
    }

    Carregar(){

        this.overlay = true;

        this.turmaService.GerarCertificado(this.filtro.turmaId, this.filtro.idAluno, this.filtro.idUsuario).then(
            res =>{
                this.item = res.data;
            },
            err =>{
                this.$swal('Aviso',err.response.data,'error')
            }
        ).finally(() => {
            this.overlay = false;
        })
    }

    setPageSize(size: string){
        const style = document.createElement('style');
        style.innerHTML = `@page {size: ${size}}`;
        document.head.appendChild(style);
    }

    Imprimir(){
        window.print();
    }

    Fechar(){
        window.close();
    }
}
